import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {

  connect() {

    let backdrop = document.querySelector(".modal-backdrop")
    if (backdrop) { backdrop.remove() }

    this.modal = new Modal(this.element)
    this.modal.show()

    this.element.addEventListener('hidden.bs.modal', () => {
      this.element.remove()
    })

    //TODO: Here first find elements with autofocus and then try to find something focusable
    this.element.addEventListener('shown.bs.modal', () => {
      let firstField = this.element.querySelectorAll(
        'form.simple_form:not([data-skip-focus="true"])[class*="new_"] .form-control:not([data-skip-focus="true"]), form.simple_form:not([data-skip-focus="true"])[class*="new_"] select'
      )[0]
      if (firstField) {
        if (firstField.tomselect) {
          firstField.tomselect.focus()
        } else {
          firstField.focus()
        }
      }
    })
  }

  close() {
    this.modal.hide()
  }

}
