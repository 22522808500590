import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["root", "activator"];
  static values = {
    persistentId: String,
  };

  connect() {
    // get css classes for all groups
    this.group_classes = {};
    this.activatorTargets.forEach((element) => {
      const group = element.dataset.group;
      const css_class = element.dataset.class;
      if (!this.group_classes.hasOwnProperty(group)) {
        this.group_classes[group] = [];
      }
      if (!this.group_classes[group].includes(css_class)) {
        this.group_classes[group].push(css_class);
      }
    });

    // try to set css classes saved in persistent storage if enabled
    if (this.persistentIdValue != "") {
      Object.entries(this.group_classes).forEach(([group, css_classes]) => {
        const ls_class = localStorage.getItem(this.persistentIdValue + group);
        if (ls_class == null) return;
        css_classes.forEach((cl) => {
          this.rootTarget.classList.remove(cl);
        });
        this.rootTarget.classList.add(ls_class);
      });
    }
  }

  activate(event) {
    const target_group = event.currentTarget.dataset.group;
    const target_class = event.currentTarget.dataset.class;
    // disable all css classes for this group
    this.group_classes[target_group].forEach((cl) => {
      this.rootTarget.classList.remove(cl);
    });
    // enable target css class
    this.rootTarget.classList.add(target_class);

    // save target class to persistent storage if enabled
    if (this.persistentIdValue != "") {
      localStorage.setItem(this.persistentIdValue + target_group, target_class);
    }
  }
}
