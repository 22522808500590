import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    targetText: String,
  };

  connect() {}

  copy(event) {
    event.preventDefault();
    navigator.clipboard.writeText(this.targetTextValue);
  }
}
