import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = { url: String }

  connect() {
    this.element.addEventListener('click', e => {
      e.preventDefault()
      e.stopPropagation()
      Turbo.visit(this.urlValue, {frame: 'modal'})
    })
  }

}
