import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper/bundle"

export default class extends Controller {

  static values = {
    autoplay: Number,
    speed: Number
  }

  connect() {

    let main_swiper_args = {
      spaceBetween: 10,
      loop: true,
      speed: 1000,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }
    }

    const preview_swiper = this.element.querySelector(".preview-swiper")

    if (preview_swiper) {
      this.preview_swiper = new Swiper(".preview-swiper", {
        spaceBetween: 10,
        slidesPerView: "auto",
        freeMode: true,
        watchSlidesProgress: true,
      })
      main_swiper_args.thumbs = {
        swiper: this.preview_swiper
      }
    }

    const swiper_pagination = this.element.querySelector(".swiper-pagination")

    if (swiper_pagination) {
      main_swiper_args.pagination = {
        el: ".swiper-pagination",
        clickable: true,
      }
    }

    if (this.hasAutoplayValue) {
      main_swiper_args.autoplay = {
        delay: this.autoplayValue,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      }
    }

    if (this.hasSpeedValue) {
      main_swiper_args.speed = this.speedValue
    }

    this.main_swiper = new Swiper(".main-swiper", main_swiper_args)
  }

}
