// load ACE editor modules - by blackjam
// module list taken from https://raw.githubusercontent.com/ajaxorg/ace-builds/master/webpack-resolver.js
// loading by vite according to https://github.com/securingsincity/react-ace/issues/725#issuecomment-1086221818

import ace from 'ace-builds/src-noconflict/ace'

import ext_beautify from 'ace-builds/src-noconflict/ext-beautify.js?url'
ace.config.setModuleUrl('ace/ext/beautify', ext_beautify)
import ext_code_lens from 'ace-builds/src-noconflict/ext-code_lens.js?url'
ace.config.setModuleUrl('ace/ext/code_lens', ext_code_lens)
import ext_elastic_tabstops_lite from 'ace-builds/src-noconflict/ext-elastic_tabstops_lite.js?url'
ace.config.setModuleUrl('ace/ext/elastic_tabstops_lite', ext_elastic_tabstops_lite)
import ext_emmet from 'ace-builds/src-noconflict/ext-emmet.js?url'
ace.config.setModuleUrl('ace/ext/emmet', ext_emmet)
import ext_error_marker from 'ace-builds/src-noconflict/ext-error_marker.js?url'
ace.config.setModuleUrl('ace/ext/error_marker', ext_error_marker)
import ext_hardwrap from 'ace-builds/src-noconflict/ext-hardwrap.js?url'
ace.config.setModuleUrl('ace/ext/hardwrap', ext_hardwrap)
import ext_keybinding_menu from 'ace-builds/src-noconflict/ext-keybinding_menu.js?url'
ace.config.setModuleUrl('ace/ext/keyboard_menu', ext_keybinding_menu)
import ext_language_tools from 'ace-builds/src-noconflict/ext-language_tools.js?url'
ace.config.setModuleUrl('ace/ext/language_tools', ext_language_tools)
import ext_linking from 'ace-builds/src-noconflict/ext-linking.js?url'
ace.config.setModuleUrl('ace/ext/linking', ext_linking)
import ext_modelist from 'ace-builds/src-noconflict/ext-modelist.js?url'
ace.config.setModuleUrl('ace/ext/modelist', ext_modelist)
import ext_options from 'ace-builds/src-noconflict/ext-options.js?url'
ace.config.setModuleUrl('ace/ext/options', ext_options)
import ext_prompt from 'ace-builds/src-noconflict/ext-prompt.js?url'
ace.config.setModuleUrl('ace/ext/prompt', ext_prompt)
import ext_rtl from 'ace-builds/src-noconflict/ext-rtl.js?url'
ace.config.setModuleUrl('ace/ext/rtl', ext_rtl)
import ext_searchbox from 'ace-builds/src-noconflict/ext-searchbox.js?url'
ace.config.setModuleUrl('ace/ext/searchbox', ext_searchbox)
import ext_settings_menu from 'ace-builds/src-noconflict/ext-settings_menu.js?url'
ace.config.setModuleUrl('ace/ext/settings_menu', ext_settings_menu)
import ext_spellcheck from 'ace-builds/src-noconflict/ext-spellcheck.js?url'
ace.config.setModuleUrl('ace/ext/spellcheck', ext_spellcheck)
import ext_split from 'ace-builds/src-noconflict/ext-split.js?url'
ace.config.setModuleUrl('ace/ext/split', ext_split)
import ext_static_highlight from 'ace-builds/src-noconflict/ext-static_highlight.js?url'
ace.config.setModuleUrl('ace/ext/static_highlight', ext_static_highlight)
import ext_statusbar from 'ace-builds/src-noconflict/ext-statusbar.js?url'
ace.config.setModuleUrl('ace/ext/statusbar', ext_statusbar)
import ext_textarea from 'ace-builds/src-noconflict/ext-textarea.js?url'
ace.config.setModuleUrl('ace/ext/textarea', ext_textarea)
import ext_themelist from 'ace-builds/src-noconflict/ext-themelist.js?url'
ace.config.setModuleUrl('ace/ext/themelist', ext_themelist)
import ext_whitespace from 'ace-builds/src-noconflict/ext-whitespace.js?url'
ace.config.setModuleUrl('ace/ext/whitespace', ext_whitespace)
import keybinding_emacs from 'ace-builds/src-noconflict/keybinding-emacs.js?url'
ace.config.setModuleUrl('ace/keyboard/emacs', keybinding_emacs)
import keybinding_sublime from 'ace-builds/src-noconflict/keybinding-sublime.js?url'
ace.config.setModuleUrl('ace/keyboard/sublime', keybinding_sublime)
import keybinding_vim from 'ace-builds/src-noconflict/keybinding-vim.js?url'
ace.config.setModuleUrl('ace/keyboard/vim', keybinding_vim)
import keybinding_vscode from 'ace-builds/src-noconflict/keybinding-vscode.js?url'
ace.config.setModuleUrl('ace/keyboard/vscode', keybinding_vscode)
import mode_abap from 'ace-builds/src-noconflict/mode-abap.js?url'
ace.config.setModuleUrl('ace/mode/abap', mode_abap)
import mode_abc from 'ace-builds/src-noconflict/mode-abc.js?url'
ace.config.setModuleUrl('ace/mode/abc', mode_abc)
import mode_actionscript from 'ace-builds/src-noconflict/mode-actionscript.js?url'
ace.config.setModuleUrl('ace/mode/actionscript', mode_actionscript)
import mode_ada from 'ace-builds/src-noconflict/mode-ada.js?url'
ace.config.setModuleUrl('ace/mode/ada', mode_ada)
import mode_alda from 'ace-builds/src-noconflict/mode-alda.js?url'
ace.config.setModuleUrl('ace/mode/alda', mode_alda)
import mode_apache_conf from 'ace-builds/src-noconflict/mode-apache_conf.js?url'
ace.config.setModuleUrl('ace/mode/apache_conf', mode_apache_conf)
import mode_apex from 'ace-builds/src-noconflict/mode-apex.js?url'
ace.config.setModuleUrl('ace/mode/apex', mode_apex)
import mode_applescript from 'ace-builds/src-noconflict/mode-applescript.js?url'
ace.config.setModuleUrl('ace/mode/applescript', mode_applescript)
import mode_aql from 'ace-builds/src-noconflict/mode-aql.js?url'
ace.config.setModuleUrl('ace/mode/aql', mode_aql)
import mode_asciidoc from 'ace-builds/src-noconflict/mode-asciidoc.js?url'
ace.config.setModuleUrl('ace/mode/asciidoc', mode_asciidoc)
import mode_asl from 'ace-builds/src-noconflict/mode-asl.js?url'
ace.config.setModuleUrl('ace/mode/asl', mode_asl)
import mode_assembly_x86 from 'ace-builds/src-noconflict/mode-assembly_x86.js?url'
ace.config.setModuleUrl('ace/mode/assembly_x86', mode_assembly_x86)
import mode_autohotkey from 'ace-builds/src-noconflict/mode-autohotkey.js?url'
ace.config.setModuleUrl('ace/mode/autohotkey', mode_autohotkey)
import mode_batchfile from 'ace-builds/src-noconflict/mode-batchfile.js?url'
ace.config.setModuleUrl('ace/mode/batchfile', mode_batchfile)
import mode_c9search from 'ace-builds/src-noconflict/mode-c9search.js?url'
ace.config.setModuleUrl('ace/mode/c9search', mode_c9search)
import mode_c_cpp from 'ace-builds/src-noconflict/mode-c_cpp.js?url'
ace.config.setModuleUrl('ace/mode/c_cpp', mode_c_cpp)
import mode_cirru from 'ace-builds/src-noconflict/mode-cirru.js?url'
ace.config.setModuleUrl('ace/mode/cirru', mode_cirru)
import mode_clojure from 'ace-builds/src-noconflict/mode-clojure.js?url'
ace.config.setModuleUrl('ace/mode/clojure', mode_clojure)
import mode_cobol from 'ace-builds/src-noconflict/mode-cobol.js?url'
ace.config.setModuleUrl('ace/mode/cobol', mode_cobol)
import mode_coffee from 'ace-builds/src-noconflict/mode-coffee.js?url'
ace.config.setModuleUrl('ace/mode/coffee', mode_coffee)
import mode_coldfusion from 'ace-builds/src-noconflict/mode-coldfusion.js?url'
ace.config.setModuleUrl('ace/mode/coldfusion', mode_coldfusion)
import mode_crystal from 'ace-builds/src-noconflict/mode-crystal.js?url'
ace.config.setModuleUrl('ace/mode/crystal', mode_crystal)
import mode_csharp from 'ace-builds/src-noconflict/mode-csharp.js?url'
ace.config.setModuleUrl('ace/mode/csharp', mode_csharp)
import mode_csound_document from 'ace-builds/src-noconflict/mode-csound_document.js?url'
ace.config.setModuleUrl('ace/mode/csound_document', mode_csound_document)
import mode_csound_orchestra from 'ace-builds/src-noconflict/mode-csound_orchestra.js?url'
ace.config.setModuleUrl('ace/mode/csound_orchestra', mode_csound_orchestra)
import mode_csound_score from 'ace-builds/src-noconflict/mode-csound_score.js?url'
ace.config.setModuleUrl('ace/mode/csound_score', mode_csound_score)
import mode_csp from 'ace-builds/src-noconflict/mode-csp.js?url'
ace.config.setModuleUrl('ace/mode/csp', mode_csp)
import mode_css from 'ace-builds/src-noconflict/mode-css.js?url'
ace.config.setModuleUrl('ace/mode/css', mode_css)
import mode_curly from 'ace-builds/src-noconflict/mode-curly.js?url'
ace.config.setModuleUrl('ace/mode/curly', mode_curly)
import mode_d from 'ace-builds/src-noconflict/mode-d.js?url'
ace.config.setModuleUrl('ace/mode/d', mode_d)
import mode_dart from 'ace-builds/src-noconflict/mode-dart.js?url'
ace.config.setModuleUrl('ace/mode/dart', mode_dart)
import mode_diff from 'ace-builds/src-noconflict/mode-diff.js?url'
ace.config.setModuleUrl('ace/mode/diff', mode_diff)
import mode_django from 'ace-builds/src-noconflict/mode-django.js?url'
ace.config.setModuleUrl('ace/mode/django', mode_django)
import mode_dockerfile from 'ace-builds/src-noconflict/mode-dockerfile.js?url'
ace.config.setModuleUrl('ace/mode/dockerfile', mode_dockerfile)
import mode_dot from 'ace-builds/src-noconflict/mode-dot.js?url'
ace.config.setModuleUrl('ace/mode/dot', mode_dot)
import mode_drools from 'ace-builds/src-noconflict/mode-drools.js?url'
ace.config.setModuleUrl('ace/mode/drools', mode_drools)
import mode_edifact from 'ace-builds/src-noconflict/mode-edifact.js?url'
ace.config.setModuleUrl('ace/mode/edifact', mode_edifact)
import mode_eiffel from 'ace-builds/src-noconflict/mode-eiffel.js?url'
ace.config.setModuleUrl('ace/mode/eiffel', mode_eiffel)
import mode_ejs from 'ace-builds/src-noconflict/mode-ejs.js?url'
ace.config.setModuleUrl('ace/mode/ejs', mode_ejs)
import mode_elixir from 'ace-builds/src-noconflict/mode-elixir.js?url'
ace.config.setModuleUrl('ace/mode/elixir', mode_elixir)
import mode_elm from 'ace-builds/src-noconflict/mode-elm.js?url'
ace.config.setModuleUrl('ace/mode/elm', mode_elm)
import mode_erlang from 'ace-builds/src-noconflict/mode-erlang.js?url'
ace.config.setModuleUrl('ace/mode/erlang', mode_erlang)
import mode_forth from 'ace-builds/src-noconflict/mode-forth.js?url'
ace.config.setModuleUrl('ace/mode/forth', mode_forth)
import mode_fortran from 'ace-builds/src-noconflict/mode-fortran.js?url'
ace.config.setModuleUrl('ace/mode/fortran', mode_fortran)
import mode_fsharp from 'ace-builds/src-noconflict/mode-fsharp.js?url'
ace.config.setModuleUrl('ace/mode/fsharp', mode_fsharp)
import mode_fsl from 'ace-builds/src-noconflict/mode-fsl.js?url'
ace.config.setModuleUrl('ace/mode/fsl', mode_fsl)
import mode_ftl from 'ace-builds/src-noconflict/mode-ftl.js?url'
ace.config.setModuleUrl('ace/mode/ftl', mode_ftl)
import mode_gcode from 'ace-builds/src-noconflict/mode-gcode.js?url'
ace.config.setModuleUrl('ace/mode/gcode', mode_gcode)
import mode_gherkin from 'ace-builds/src-noconflict/mode-gherkin.js?url'
ace.config.setModuleUrl('ace/mode/gherkin', mode_gherkin)
import mode_gitignore from 'ace-builds/src-noconflict/mode-gitignore.js?url'
ace.config.setModuleUrl('ace/mode/gitignore', mode_gitignore)
import mode_glsl from 'ace-builds/src-noconflict/mode-glsl.js?url'
ace.config.setModuleUrl('ace/mode/glsl', mode_glsl)
import mode_gobstones from 'ace-builds/src-noconflict/mode-gobstones.js?url'
ace.config.setModuleUrl('ace/mode/gobstones', mode_gobstones)
import mode_golang from 'ace-builds/src-noconflict/mode-golang.js?url'
ace.config.setModuleUrl('ace/mode/golang', mode_golang)
import mode_graphqlschema from 'ace-builds/src-noconflict/mode-graphqlschema.js?url'
ace.config.setModuleUrl('ace/mode/graphqlschema', mode_graphqlschema)
import mode_groovy from 'ace-builds/src-noconflict/mode-groovy.js?url'
ace.config.setModuleUrl('ace/mode/groovy', mode_groovy)
import mode_haml from 'ace-builds/src-noconflict/mode-haml.js?url'
ace.config.setModuleUrl('ace/mode/haml', mode_haml)
import mode_handlebars from 'ace-builds/src-noconflict/mode-handlebars.js?url'
ace.config.setModuleUrl('ace/mode/handlebars', mode_handlebars)
import mode_haskell from 'ace-builds/src-noconflict/mode-haskell.js?url'
ace.config.setModuleUrl('ace/mode/haskell', mode_haskell)
import mode_haskell_cabal from 'ace-builds/src-noconflict/mode-haskell_cabal.js?url'
ace.config.setModuleUrl('ace/mode/haskell_cabal', mode_haskell_cabal)
import mode_haxe from 'ace-builds/src-noconflict/mode-haxe.js?url'
ace.config.setModuleUrl('ace/mode/haxe', mode_haxe)
import mode_hjson from 'ace-builds/src-noconflict/mode-hjson.js?url'
ace.config.setModuleUrl('ace/mode/hjson', mode_hjson)
import mode_html from 'ace-builds/src-noconflict/mode-html.js?url'
ace.config.setModuleUrl('ace/mode/html', mode_html)
import mode_html_elixir from 'ace-builds/src-noconflict/mode-html_elixir.js?url'
ace.config.setModuleUrl('ace/mode/html_elixir', mode_html_elixir)
import mode_html_ruby from 'ace-builds/src-noconflict/mode-html_ruby.js?url'
ace.config.setModuleUrl('ace/mode/html_ruby', mode_html_ruby)
import mode_ini from 'ace-builds/src-noconflict/mode-ini.js?url'
ace.config.setModuleUrl('ace/mode/ini', mode_ini)
import mode_io from 'ace-builds/src-noconflict/mode-io.js?url'
ace.config.setModuleUrl('ace/mode/io', mode_io)
import mode_jack from 'ace-builds/src-noconflict/mode-jack.js?url'
ace.config.setModuleUrl('ace/mode/jack', mode_jack)
import mode_jade from 'ace-builds/src-noconflict/mode-jade.js?url'
ace.config.setModuleUrl('ace/mode/jade', mode_jade)
import mode_java from 'ace-builds/src-noconflict/mode-java.js?url'
ace.config.setModuleUrl('ace/mode/java', mode_java)
import mode_javascript from 'ace-builds/src-noconflict/mode-javascript.js?url'
ace.config.setModuleUrl('ace/mode/javascript', mode_javascript)
import mode_json from 'ace-builds/src-noconflict/mode-json.js?url'
ace.config.setModuleUrl('ace/mode/json', mode_json)
import mode_json5 from 'ace-builds/src-noconflict/mode-json5.js?url'
ace.config.setModuleUrl('ace/mode/json5', mode_json5)
import mode_jsoniq from 'ace-builds/src-noconflict/mode-jsoniq.js?url'
ace.config.setModuleUrl('ace/mode/jsoniq', mode_jsoniq)
import mode_jsp from 'ace-builds/src-noconflict/mode-jsp.js?url'
ace.config.setModuleUrl('ace/mode/jsp', mode_jsp)
import mode_jssm from 'ace-builds/src-noconflict/mode-jssm.js?url'
ace.config.setModuleUrl('ace/mode/jssm', mode_jssm)
import mode_jsx from 'ace-builds/src-noconflict/mode-jsx.js?url'
ace.config.setModuleUrl('ace/mode/jsx', mode_jsx)
import mode_julia from 'ace-builds/src-noconflict/mode-julia.js?url'
ace.config.setModuleUrl('ace/mode/julia', mode_julia)
import mode_kotlin from 'ace-builds/src-noconflict/mode-kotlin.js?url'
ace.config.setModuleUrl('ace/mode/kotlin', mode_kotlin)
import mode_latex from 'ace-builds/src-noconflict/mode-latex.js?url'
ace.config.setModuleUrl('ace/mode/latex', mode_latex)
import mode_latte from 'ace-builds/src-noconflict/mode-latte.js?url'
ace.config.setModuleUrl('ace/mode/latte', mode_latte)
import mode_less from 'ace-builds/src-noconflict/mode-less.js?url'
ace.config.setModuleUrl('ace/mode/less', mode_less)
import mode_liquid from 'ace-builds/src-noconflict/mode-liquid.js?url'
ace.config.setModuleUrl('ace/mode/liquid', mode_liquid)
import mode_lisp from 'ace-builds/src-noconflict/mode-lisp.js?url'
ace.config.setModuleUrl('ace/mode/lisp', mode_lisp)
import mode_livescript from 'ace-builds/src-noconflict/mode-livescript.js?url'
ace.config.setModuleUrl('ace/mode/livescript', mode_livescript)
import mode_logiql from 'ace-builds/src-noconflict/mode-logiql.js?url'
ace.config.setModuleUrl('ace/mode/logiql', mode_logiql)
import mode_logtalk from 'ace-builds/src-noconflict/mode-logtalk.js?url'
ace.config.setModuleUrl('ace/mode/logtalk', mode_logtalk)
import mode_lsl from 'ace-builds/src-noconflict/mode-lsl.js?url'
ace.config.setModuleUrl('ace/mode/lsl', mode_lsl)
import mode_lua from 'ace-builds/src-noconflict/mode-lua.js?url'
ace.config.setModuleUrl('ace/mode/lua', mode_lua)
import mode_luapage from 'ace-builds/src-noconflict/mode-luapage.js?url'
ace.config.setModuleUrl('ace/mode/luapage', mode_luapage)
import mode_lucene from 'ace-builds/src-noconflict/mode-lucene.js?url'
ace.config.setModuleUrl('ace/mode/lucene', mode_lucene)
import mode_makefile from 'ace-builds/src-noconflict/mode-makefile.js?url'
ace.config.setModuleUrl('ace/mode/makefile', mode_makefile)
import mode_markdown from 'ace-builds/src-noconflict/mode-markdown.js?url'
ace.config.setModuleUrl('ace/mode/markdown', mode_markdown)
import mode_mask from 'ace-builds/src-noconflict/mode-mask.js?url'
ace.config.setModuleUrl('ace/mode/mask', mode_mask)
import mode_matlab from 'ace-builds/src-noconflict/mode-matlab.js?url'
ace.config.setModuleUrl('ace/mode/matlab', mode_matlab)
import mode_maze from 'ace-builds/src-noconflict/mode-maze.js?url'
ace.config.setModuleUrl('ace/mode/maze', mode_maze)
import mode_mediawiki from 'ace-builds/src-noconflict/mode-mediawiki.js?url'
ace.config.setModuleUrl('ace/mode/mediawiki', mode_mediawiki)
import mode_mel from 'ace-builds/src-noconflict/mode-mel.js?url'
ace.config.setModuleUrl('ace/mode/mel', mode_mel)
import mode_mips from 'ace-builds/src-noconflict/mode-mips.js?url'
ace.config.setModuleUrl('ace/mode/mips', mode_mips)
import mode_mixal from 'ace-builds/src-noconflict/mode-mixal.js?url'
ace.config.setModuleUrl('ace/mode/mixal', mode_mixal)
import mode_mushcode from 'ace-builds/src-noconflict/mode-mushcode.js?url'
ace.config.setModuleUrl('ace/mode/mushcode', mode_mushcode)
import mode_mysql from 'ace-builds/src-noconflict/mode-mysql.js?url'
ace.config.setModuleUrl('ace/mode/mysql', mode_mysql)
import mode_nginx from 'ace-builds/src-noconflict/mode-nginx.js?url'
ace.config.setModuleUrl('ace/mode/nginx', mode_nginx)
import mode_nim from 'ace-builds/src-noconflict/mode-nim.js?url'
ace.config.setModuleUrl('ace/mode/nim', mode_nim)
import mode_nix from 'ace-builds/src-noconflict/mode-nix.js?url'
ace.config.setModuleUrl('ace/mode/nix', mode_nix)
import mode_nsis from 'ace-builds/src-noconflict/mode-nsis.js?url'
ace.config.setModuleUrl('ace/mode/nsis', mode_nsis)
import mode_nunjucks from 'ace-builds/src-noconflict/mode-nunjucks.js?url'
ace.config.setModuleUrl('ace/mode/nunjucks', mode_nunjucks)
import mode_objectivec from 'ace-builds/src-noconflict/mode-objectivec.js?url'
ace.config.setModuleUrl('ace/mode/objectivec', mode_objectivec)
import mode_ocaml from 'ace-builds/src-noconflict/mode-ocaml.js?url'
ace.config.setModuleUrl('ace/mode/ocaml', mode_ocaml)
import mode_pascal from 'ace-builds/src-noconflict/mode-pascal.js?url'
ace.config.setModuleUrl('ace/mode/pascal', mode_pascal)
import mode_perl from 'ace-builds/src-noconflict/mode-perl.js?url'
ace.config.setModuleUrl('ace/mode/perl', mode_perl)
import mode_pgsql from 'ace-builds/src-noconflict/mode-pgsql.js?url'
ace.config.setModuleUrl('ace/mode/pgsql', mode_pgsql)
import mode_php from 'ace-builds/src-noconflict/mode-php.js?url'
ace.config.setModuleUrl('ace/mode/php', mode_php)
import mode_php_laravel_blade from 'ace-builds/src-noconflict/mode-php_laravel_blade.js?url'
ace.config.setModuleUrl('ace/mode/php_laravel_blade', mode_php_laravel_blade)
import mode_pig from 'ace-builds/src-noconflict/mode-pig.js?url'
ace.config.setModuleUrl('ace/mode/pig', mode_pig)
import mode_plain_text from 'ace-builds/src-noconflict/mode-plain_text.js?url'
ace.config.setModuleUrl('ace/mode/plain_text', mode_plain_text)
import mode_powershell from 'ace-builds/src-noconflict/mode-powershell.js?url'
ace.config.setModuleUrl('ace/mode/powershell', mode_powershell)
import mode_praat from 'ace-builds/src-noconflict/mode-praat.js?url'
ace.config.setModuleUrl('ace/mode/praat', mode_praat)
import mode_prisma from 'ace-builds/src-noconflict/mode-prisma.js?url'
ace.config.setModuleUrl('ace/mode/prisma', mode_prisma)
import mode_prolog from 'ace-builds/src-noconflict/mode-prolog.js?url'
ace.config.setModuleUrl('ace/mode/prolog', mode_prolog)
import mode_properties from 'ace-builds/src-noconflict/mode-properties.js?url'
ace.config.setModuleUrl('ace/mode/properties', mode_properties)
import mode_protobuf from 'ace-builds/src-noconflict/mode-protobuf.js?url'
ace.config.setModuleUrl('ace/mode/protobuf', mode_protobuf)
import mode_puppet from 'ace-builds/src-noconflict/mode-puppet.js?url'
ace.config.setModuleUrl('ace/mode/puppet', mode_puppet)
import mode_python from 'ace-builds/src-noconflict/mode-python.js?url'
ace.config.setModuleUrl('ace/mode/python', mode_python)
import mode_qml from 'ace-builds/src-noconflict/mode-qml.js?url'
ace.config.setModuleUrl('ace/mode/qml', mode_qml)
import mode_r from 'ace-builds/src-noconflict/mode-r.js?url'
ace.config.setModuleUrl('ace/mode/r', mode_r)
import mode_raku from 'ace-builds/src-noconflict/mode-raku.js?url'
ace.config.setModuleUrl('ace/mode/raku', mode_raku)
import mode_razor from 'ace-builds/src-noconflict/mode-razor.js?url'
ace.config.setModuleUrl('ace/mode/razor', mode_razor)
import mode_rdoc from 'ace-builds/src-noconflict/mode-rdoc.js?url'
ace.config.setModuleUrl('ace/mode/rdoc', mode_rdoc)
import mode_red from 'ace-builds/src-noconflict/mode-red.js?url'
ace.config.setModuleUrl('ace/mode/red', mode_red)
import mode_redshift from 'ace-builds/src-noconflict/mode-redshift.js?url'
ace.config.setModuleUrl('ace/mode/redshift', mode_redshift)
import mode_rhtml from 'ace-builds/src-noconflict/mode-rhtml.js?url'
ace.config.setModuleUrl('ace/mode/rhtml', mode_rhtml)
import mode_rst from 'ace-builds/src-noconflict/mode-rst.js?url'
ace.config.setModuleUrl('ace/mode/rst', mode_rst)
import mode_ruby from 'ace-builds/src-noconflict/mode-ruby.js?url'
ace.config.setModuleUrl('ace/mode/ruby', mode_ruby)
import mode_rust from 'ace-builds/src-noconflict/mode-rust.js?url'
ace.config.setModuleUrl('ace/mode/rust', mode_rust)
import mode_sass from 'ace-builds/src-noconflict/mode-sass.js?url'
ace.config.setModuleUrl('ace/mode/sass', mode_sass)
import mode_scad from 'ace-builds/src-noconflict/mode-scad.js?url'
ace.config.setModuleUrl('ace/mode/scad', mode_scad)
import mode_scala from 'ace-builds/src-noconflict/mode-scala.js?url'
ace.config.setModuleUrl('ace/mode/scala', mode_scala)
import mode_scheme from 'ace-builds/src-noconflict/mode-scheme.js?url'
ace.config.setModuleUrl('ace/mode/scheme', mode_scheme)
import mode_scrypt from 'ace-builds/src-noconflict/mode-scrypt.js?url'
ace.config.setModuleUrl('ace/mode/scrypt', mode_scrypt)
import mode_scss from 'ace-builds/src-noconflict/mode-scss.js?url'
ace.config.setModuleUrl('ace/mode/scss', mode_scss)
import mode_sh from 'ace-builds/src-noconflict/mode-sh.js?url'
ace.config.setModuleUrl('ace/mode/sh', mode_sh)
import mode_sjs from 'ace-builds/src-noconflict/mode-sjs.js?url'
ace.config.setModuleUrl('ace/mode/sjs', mode_sjs)
import mode_slim from 'ace-builds/src-noconflict/mode-slim.js?url'
ace.config.setModuleUrl('ace/mode/slim', mode_slim)
import mode_smarty from 'ace-builds/src-noconflict/mode-smarty.js?url'
ace.config.setModuleUrl('ace/mode/smarty', mode_smarty)
import mode_smithy from 'ace-builds/src-noconflict/mode-smithy.js?url'
ace.config.setModuleUrl('ace/mode/smithy', mode_smithy)
import mode_snippets from 'ace-builds/src-noconflict/mode-snippets.js?url'
ace.config.setModuleUrl('ace/mode/snippets', mode_snippets)
import mode_soy_template from 'ace-builds/src-noconflict/mode-soy_template.js?url'
ace.config.setModuleUrl('ace/mode/soy_template', mode_soy_template)
import mode_space from 'ace-builds/src-noconflict/mode-space.js?url'
ace.config.setModuleUrl('ace/mode/space', mode_space)
import mode_sparql from 'ace-builds/src-noconflict/mode-sparql.js?url'
ace.config.setModuleUrl('ace/mode/sparql', mode_sparql)
import mode_sql from 'ace-builds/src-noconflict/mode-sql.js?url'
ace.config.setModuleUrl('ace/mode/sql', mode_sql)
import mode_sqlserver from 'ace-builds/src-noconflict/mode-sqlserver.js?url'
ace.config.setModuleUrl('ace/mode/sqlserver', mode_sqlserver)
import mode_stylus from 'ace-builds/src-noconflict/mode-stylus.js?url'
ace.config.setModuleUrl('ace/mode/stylus', mode_stylus)
import mode_svg from 'ace-builds/src-noconflict/mode-svg.js?url'
ace.config.setModuleUrl('ace/mode/svg', mode_svg)
import mode_swift from 'ace-builds/src-noconflict/mode-swift.js?url'
ace.config.setModuleUrl('ace/mode/swift', mode_swift)
import mode_tcl from 'ace-builds/src-noconflict/mode-tcl.js?url'
ace.config.setModuleUrl('ace/mode/tcl', mode_tcl)
import mode_terraform from 'ace-builds/src-noconflict/mode-terraform.js?url'
ace.config.setModuleUrl('ace/mode/terraform', mode_terraform)
import mode_tex from 'ace-builds/src-noconflict/mode-tex.js?url'
ace.config.setModuleUrl('ace/mode/tex', mode_tex)
import mode_text from 'ace-builds/src-noconflict/mode-text.js?url'
ace.config.setModuleUrl('ace/mode/text', mode_text)
import mode_textile from 'ace-builds/src-noconflict/mode-textile.js?url'
ace.config.setModuleUrl('ace/mode/textile', mode_textile)
import mode_toml from 'ace-builds/src-noconflict/mode-toml.js?url'
ace.config.setModuleUrl('ace/mode/toml', mode_toml)
import mode_tsx from 'ace-builds/src-noconflict/mode-tsx.js?url'
ace.config.setModuleUrl('ace/mode/tsx', mode_tsx)
import mode_turtle from 'ace-builds/src-noconflict/mode-turtle.js?url'
ace.config.setModuleUrl('ace/mode/turtle', mode_turtle)
import mode_twig from 'ace-builds/src-noconflict/mode-twig.js?url'
ace.config.setModuleUrl('ace/mode/twig', mode_twig)
import mode_typescript from 'ace-builds/src-noconflict/mode-typescript.js?url'
ace.config.setModuleUrl('ace/mode/typescript', mode_typescript)
import mode_vala from 'ace-builds/src-noconflict/mode-vala.js?url'
ace.config.setModuleUrl('ace/mode/vala', mode_vala)
import mode_vbscript from 'ace-builds/src-noconflict/mode-vbscript.js?url'
ace.config.setModuleUrl('ace/mode/vbscript', mode_vbscript)
import mode_velocity from 'ace-builds/src-noconflict/mode-velocity.js?url'
ace.config.setModuleUrl('ace/mode/velocity', mode_velocity)
import mode_verilog from 'ace-builds/src-noconflict/mode-verilog.js?url'
ace.config.setModuleUrl('ace/mode/verilog', mode_verilog)
import mode_vhdl from 'ace-builds/src-noconflict/mode-vhdl.js?url'
ace.config.setModuleUrl('ace/mode/vhdl', mode_vhdl)
import mode_visualforce from 'ace-builds/src-noconflict/mode-visualforce.js?url'
ace.config.setModuleUrl('ace/mode/visualforce', mode_visualforce)
import mode_wollok from 'ace-builds/src-noconflict/mode-wollok.js?url'
ace.config.setModuleUrl('ace/mode/wollok', mode_wollok)
import mode_xml from 'ace-builds/src-noconflict/mode-xml.js?url'
ace.config.setModuleUrl('ace/mode/xml', mode_xml)
import mode_xquery from 'ace-builds/src-noconflict/mode-xquery.js?url'
ace.config.setModuleUrl('ace/mode/xquery', mode_xquery)
import mode_yaml from 'ace-builds/src-noconflict/mode-yaml.js?url'
ace.config.setModuleUrl('ace/mode/yaml', mode_yaml)
import mode_zeek from 'ace-builds/src-noconflict/mode-zeek.js?url'
ace.config.setModuleUrl('ace/mode/zeek', mode_zeek)

import theme_ambiance from 'ace-builds/src-noconflict/theme-ambiance.js?url'
ace.config.setModuleUrl('ace/theme/ambiance', theme_ambiance)
import theme_chaos from 'ace-builds/src-noconflict/theme-chaos.js?url'
ace.config.setModuleUrl('ace/theme/chaos', theme_chaos)
import theme_chrome from 'ace-builds/src-noconflict/theme-chrome.js?url'
ace.config.setModuleUrl('ace/theme/chrome', theme_chrome)
import theme_clouds from 'ace-builds/src-noconflict/theme-clouds.js?url'
ace.config.setModuleUrl('ace/theme/clouds', theme_clouds)
import theme_clouds_midnight from 'ace-builds/src-noconflict/theme-clouds_midnight.js?url'
ace.config.setModuleUrl('ace/theme/clouds_midnight', theme_clouds_midnight)
import theme_cobalt from 'ace-builds/src-noconflict/theme-cobalt.js?url'
ace.config.setModuleUrl('ace/theme/cobalt', theme_cobalt)
import theme_crimson_editor from 'ace-builds/src-noconflict/theme-crimson_editor.js?url'
ace.config.setModuleUrl('ace/theme/crimson_editor', theme_crimson_editor)
import theme_dawn from 'ace-builds/src-noconflict/theme-dawn.js?url'
ace.config.setModuleUrl('ace/theme/dawn', theme_dawn)
import theme_dracula from 'ace-builds/src-noconflict/theme-dracula.js?url'
ace.config.setModuleUrl('ace/theme/dracula', theme_dracula)
import theme_dreamweaver from 'ace-builds/src-noconflict/theme-dreamweaver.js?url'
ace.config.setModuleUrl('ace/theme/dreamweaver', theme_dreamweaver)
import theme_eclipse from 'ace-builds/src-noconflict/theme-eclipse.js?url'
ace.config.setModuleUrl('ace/theme/eclipse', theme_eclipse)
import theme_github from 'ace-builds/src-noconflict/theme-github.js?url'
ace.config.setModuleUrl('ace/theme/github', theme_github)
import theme_gob from 'ace-builds/src-noconflict/theme-gob.js?url'
ace.config.setModuleUrl('ace/theme/gob', theme_gob)
import theme_gruvbox from 'ace-builds/src-noconflict/theme-gruvbox.js?url'
ace.config.setModuleUrl('ace/theme/gruvbox', theme_gruvbox)
import theme_idle_fingers from 'ace-builds/src-noconflict/theme-idle_fingers.js?url'
ace.config.setModuleUrl('ace/theme/idle_fingers', theme_idle_fingers)
import theme_iplastic from 'ace-builds/src-noconflict/theme-iplastic.js?url'
ace.config.setModuleUrl('ace/theme/iplastic', theme_iplastic)
import theme_katzenmilch from 'ace-builds/src-noconflict/theme-katzenmilch.js?url'
ace.config.setModuleUrl('ace/theme/katzenmilch', theme_katzenmilch)
import theme_kr_theme from 'ace-builds/src-noconflict/theme-kr_theme.js?url'
ace.config.setModuleUrl('ace/theme/kr_theme', theme_kr_theme)
import theme_kuroir from 'ace-builds/src-noconflict/theme-kuroir.js?url'
ace.config.setModuleUrl('ace/theme/kuroir', theme_kuroir)
import theme_merbivore from 'ace-builds/src-noconflict/theme-merbivore.js?url'
ace.config.setModuleUrl('ace/theme/merbivore', theme_merbivore)
import theme_merbivore_soft from 'ace-builds/src-noconflict/theme-merbivore_soft.js?url'
ace.config.setModuleUrl('ace/theme/merbivore_soft', theme_merbivore_soft)
import theme_mono_industrial from 'ace-builds/src-noconflict/theme-mono_industrial.js?url'
ace.config.setModuleUrl('ace/theme/mono_industrial', theme_mono_industrial)
import theme_monokai from 'ace-builds/src-noconflict/theme-monokai.js?url'
ace.config.setModuleUrl('ace/theme/monokai', theme_monokai)
import theme_nord_dark from 'ace-builds/src-noconflict/theme-nord_dark.js?url'
ace.config.setModuleUrl('ace/theme/nord_dark', theme_nord_dark)
import theme_one_dark from 'ace-builds/src-noconflict/theme-one_dark.js?url'
ace.config.setModuleUrl('ace/theme/one_dark', theme_one_dark)
import theme_pastel_on_dark from 'ace-builds/src-noconflict/theme-pastel_on_dark.js?url'
ace.config.setModuleUrl('ace/theme/pastel_on_dark', theme_pastel_on_dark)
import theme_solarized_dark from 'ace-builds/src-noconflict/theme-solarized_dark.js?url'
ace.config.setModuleUrl('ace/theme/solarized_dark', theme_solarized_dark)
import theme_solarized_light from 'ace-builds/src-noconflict/theme-solarized_light.js?url'
ace.config.setModuleUrl('ace/theme/solarized_light', theme_solarized_light)
import theme_sqlserver from 'ace-builds/src-noconflict/theme-sqlserver.js?url'
ace.config.setModuleUrl('ace/theme/sqlserver', theme_sqlserver)
import theme_terminal from 'ace-builds/src-noconflict/theme-terminal.js?url'
ace.config.setModuleUrl('ace/theme/terminal', theme_terminal)
import theme_textmate from 'ace-builds/src-noconflict/theme-textmate.js?url'
ace.config.setModuleUrl('ace/theme/textmate', theme_textmate)
import theme_tomorrow from 'ace-builds/src-noconflict/theme-tomorrow.js?url'
ace.config.setModuleUrl('ace/theme/tomorrow', theme_tomorrow)
import theme_tomorrow_night from 'ace-builds/src-noconflict/theme-tomorrow_night.js?url'
ace.config.setModuleUrl('ace/theme/tomorrow_night', theme_tomorrow_night)
import theme_tomorrow_night_blue from 'ace-builds/src-noconflict/theme-tomorrow_night_blue.js?url'
ace.config.setModuleUrl('ace/theme/tomorrow_night_blue', theme_tomorrow_night_blue)
import theme_tomorrow_night_bright from 'ace-builds/src-noconflict/theme-tomorrow_night_bright.js?url'
ace.config.setModuleUrl('ace/theme/tomorrow_night_bright', theme_tomorrow_night_bright)
import theme_tomorrow_night_eighties from 'ace-builds/src-noconflict/theme-tomorrow_night_eighties.js?url'
ace.config.setModuleUrl('ace/theme/tomorrow_night_eighties', theme_tomorrow_night_eighties)
import theme_twilight from 'ace-builds/src-noconflict/theme-twilight.js?url'
ace.config.setModuleUrl('ace/theme/twilight', theme_twilight)
import theme_vibrant_ink from 'ace-builds/src-noconflict/theme-vibrant_ink.js?url'
ace.config.setModuleUrl('ace/theme/vibrant_ink', theme_vibrant_ink)
import theme_xcode from 'ace-builds/src-noconflict/theme-xcode.js?url'
ace.config.setModuleUrl('ace/theme/xcode', theme_xcode)
import worker_base from 'ace-builds/src-noconflict/worker-base.js?url'
ace.config.setModuleUrl('ace/mode/base_worker', worker_base)
import worker_coffee from 'ace-builds/src-noconflict/worker-coffee.js?url'
ace.config.setModuleUrl('ace/mode/coffee_worker', worker_coffee)
import worker_css from 'ace-builds/src-noconflict/worker-css.js?url'
ace.config.setModuleUrl('ace/mode/css_worker', worker_css)
import worker_html from 'ace-builds/src-noconflict/worker-html.js?url'
ace.config.setModuleUrl('ace/mode/html_worker', worker_html)
import worker_javascript from 'ace-builds/src-noconflict/worker-javascript.js?url'
ace.config.setModuleUrl('ace/mode/javascript_worker', worker_javascript)
import worker_json from 'ace-builds/src-noconflict/worker-json.js?url'
ace.config.setModuleUrl('ace/mode/json_worker', worker_json)
import worker_lua from 'ace-builds/src-noconflict/worker-lua.js?url'
ace.config.setModuleUrl('ace/mode/lua_worker', worker_lua)
import worker_php from 'ace-builds/src-noconflict/worker-php.js?url'
ace.config.setModuleUrl('ace/mode/php_worker', worker_php)
import worker_xml from 'ace-builds/src-noconflict/worker-xml.js?url'
ace.config.setModuleUrl('ace/mode/xml_worker', worker_xml)
import worker_xquery from 'ace-builds/src-noconflict/worker-xquery.js?url'
ace.config.setModuleUrl('ace/mode/xquery_worker', worker_xquery)
import snippets_abap from 'ace-builds/src-noconflict/snippets/abap.js?url'
ace.config.setModuleUrl('ace/snippets/abap', snippets_abap)
import snippets_abc from 'ace-builds/src-noconflict/snippets/abc.js?url'
ace.config.setModuleUrl('ace/snippets/abc', snippets_abc)
import snippets_actionscript from 'ace-builds/src-noconflict/snippets/actionscript.js?url'
ace.config.setModuleUrl('ace/snippets/actionscript', snippets_actionscript)
import snippets_ada from 'ace-builds/src-noconflict/snippets/ada.js?url'
ace.config.setModuleUrl('ace/snippets/ada', snippets_ada)
import snippets_alda from 'ace-builds/src-noconflict/snippets/alda.js?url'
ace.config.setModuleUrl('ace/snippets/alda', snippets_alda)
import snippets_apache_conf from 'ace-builds/src-noconflict/snippets/apache_conf.js?url'
ace.config.setModuleUrl('ace/snippets/apache_conf', snippets_apache_conf)
import snippets_apex from 'ace-builds/src-noconflict/snippets/apex.js?url'
ace.config.setModuleUrl('ace/snippets/apex', snippets_apex)
import snippets_applescript from 'ace-builds/src-noconflict/snippets/applescript.js?url'
ace.config.setModuleUrl('ace/snippets/applescript', snippets_applescript)
import snippets_aql from 'ace-builds/src-noconflict/snippets/aql.js?url'
ace.config.setModuleUrl('ace/snippets/aql', snippets_aql)
import snippets_asciidoc from 'ace-builds/src-noconflict/snippets/asciidoc.js?url'
ace.config.setModuleUrl('ace/snippets/asciidoc', snippets_asciidoc)
import snippets_asl from 'ace-builds/src-noconflict/snippets/asl.js?url'
ace.config.setModuleUrl('ace/snippets/asl', snippets_asl)
import snippets_assembly_x86 from 'ace-builds/src-noconflict/snippets/assembly_x86.js?url'
ace.config.setModuleUrl('ace/snippets/assembly_x86', snippets_assembly_x86)
import snippets_autohotkey from 'ace-builds/src-noconflict/snippets/autohotkey.js?url'
ace.config.setModuleUrl('ace/snippets/autohotkey', snippets_autohotkey)
import snippets_batchfile from 'ace-builds/src-noconflict/snippets/batchfile.js?url'
ace.config.setModuleUrl('ace/snippets/batchfile', snippets_batchfile)
import snippets_c9search from 'ace-builds/src-noconflict/snippets/c9search.js?url'
ace.config.setModuleUrl('ace/snippets/c9search', snippets_c9search)
import snippets_c_cpp from 'ace-builds/src-noconflict/snippets/c_cpp.js?url'
ace.config.setModuleUrl('ace/snippets/c_cpp', snippets_c_cpp)
import snippets_cirru from 'ace-builds/src-noconflict/snippets/cirru.js?url'
ace.config.setModuleUrl('ace/snippets/cirru', snippets_cirru)
import snippets_clojure from 'ace-builds/src-noconflict/snippets/clojure.js?url'
ace.config.setModuleUrl('ace/snippets/clojure', snippets_clojure)
import snippets_cobol from 'ace-builds/src-noconflict/snippets/cobol.js?url'
ace.config.setModuleUrl('ace/snippets/cobol', snippets_cobol)
import snippets_coffee from 'ace-builds/src-noconflict/snippets/coffee.js?url'
ace.config.setModuleUrl('ace/snippets/coffee', snippets_coffee)
import snippets_coldfusion from 'ace-builds/src-noconflict/snippets/coldfusion.js?url'
ace.config.setModuleUrl('ace/snippets/coldfusion', snippets_coldfusion)
import snippets_crystal from 'ace-builds/src-noconflict/snippets/crystal.js?url'
ace.config.setModuleUrl('ace/snippets/crystal', snippets_crystal)
import snippets_csharp from 'ace-builds/src-noconflict/snippets/csharp.js?url'
ace.config.setModuleUrl('ace/snippets/csharp', snippets_csharp)
import snippets_csound_document from 'ace-builds/src-noconflict/snippets/csound_document.js?url'
ace.config.setModuleUrl('ace/snippets/csound_document', snippets_csound_document)
import snippets_csound_orchestra from 'ace-builds/src-noconflict/snippets/csound_orchestra.js?url'
ace.config.setModuleUrl('ace/snippets/csound_orchestra', snippets_csound_orchestra)
import snippets_csound_score from 'ace-builds/src-noconflict/snippets/csound_score.js?url'
ace.config.setModuleUrl('ace/snippets/csound_score', snippets_csound_score)
import snippets_csp from 'ace-builds/src-noconflict/snippets/csp.js?url'
ace.config.setModuleUrl('ace/snippets/csp', snippets_csp)
import snippets_css from 'ace-builds/src-noconflict/snippets/css.js?url'
ace.config.setModuleUrl('ace/snippets/css', snippets_css)
import snippets_curly from 'ace-builds/src-noconflict/snippets/curly.js?url'
ace.config.setModuleUrl('ace/snippets/curly', snippets_curly)
import snippets_d from 'ace-builds/src-noconflict/snippets/d.js?url'
ace.config.setModuleUrl('ace/snippets/d', snippets_d)
import snippets_dart from 'ace-builds/src-noconflict/snippets/dart.js?url'
ace.config.setModuleUrl('ace/snippets/dart', snippets_dart)
import snippets_diff from 'ace-builds/src-noconflict/snippets/diff.js?url'
ace.config.setModuleUrl('ace/snippets/diff', snippets_diff)
import snippets_django from 'ace-builds/src-noconflict/snippets/django.js?url'
ace.config.setModuleUrl('ace/snippets/django', snippets_django)
import snippets_dockerfile from 'ace-builds/src-noconflict/snippets/dockerfile.js?url'
ace.config.setModuleUrl('ace/snippets/dockerfile', snippets_dockerfile)
import snippets_dot from 'ace-builds/src-noconflict/snippets/dot.js?url'
ace.config.setModuleUrl('ace/snippets/dot', snippets_dot)
import snippets_drools from 'ace-builds/src-noconflict/snippets/drools.js?url'
ace.config.setModuleUrl('ace/snippets/drools', snippets_drools)
import snippets_edifact from 'ace-builds/src-noconflict/snippets/edifact.js?url'
ace.config.setModuleUrl('ace/snippets/edifact', snippets_edifact)
import snippets_eiffel from 'ace-builds/src-noconflict/snippets/eiffel.js?url'
ace.config.setModuleUrl('ace/snippets/eiffel', snippets_eiffel)
import snippets_ejs from 'ace-builds/src-noconflict/snippets/ejs.js?url'
ace.config.setModuleUrl('ace/snippets/ejs', snippets_ejs)
import snippets_elixir from 'ace-builds/src-noconflict/snippets/elixir.js?url'
ace.config.setModuleUrl('ace/snippets/elixir', snippets_elixir)
import snippets_elm from 'ace-builds/src-noconflict/snippets/elm.js?url'
ace.config.setModuleUrl('ace/snippets/elm', snippets_elm)
import snippets_erlang from 'ace-builds/src-noconflict/snippets/erlang.js?url'
ace.config.setModuleUrl('ace/snippets/erlang', snippets_erlang)
import snippets_forth from 'ace-builds/src-noconflict/snippets/forth.js?url'
ace.config.setModuleUrl('ace/snippets/forth', snippets_forth)
import snippets_fortran from 'ace-builds/src-noconflict/snippets/fortran.js?url'
ace.config.setModuleUrl('ace/snippets/fortran', snippets_fortran)
import snippets_fsharp from 'ace-builds/src-noconflict/snippets/fsharp.js?url'
ace.config.setModuleUrl('ace/snippets/fsharp', snippets_fsharp)
import snippets_fsl from 'ace-builds/src-noconflict/snippets/fsl.js?url'
ace.config.setModuleUrl('ace/snippets/fsl', snippets_fsl)
import snippets_ftl from 'ace-builds/src-noconflict/snippets/ftl.js?url'
ace.config.setModuleUrl('ace/snippets/ftl', snippets_ftl)
import snippets_gcode from 'ace-builds/src-noconflict/snippets/gcode.js?url'
ace.config.setModuleUrl('ace/snippets/gcode', snippets_gcode)
import snippets_gherkin from 'ace-builds/src-noconflict/snippets/gherkin.js?url'
ace.config.setModuleUrl('ace/snippets/gherkin', snippets_gherkin)
import snippets_gitignore from 'ace-builds/src-noconflict/snippets/gitignore.js?url'
ace.config.setModuleUrl('ace/snippets/gitignore', snippets_gitignore)
import snippets_glsl from 'ace-builds/src-noconflict/snippets/glsl.js?url'
ace.config.setModuleUrl('ace/snippets/glsl', snippets_glsl)
import snippets_gobstones from 'ace-builds/src-noconflict/snippets/gobstones.js?url'
ace.config.setModuleUrl('ace/snippets/gobstones', snippets_gobstones)
import snippets_golang from 'ace-builds/src-noconflict/snippets/golang.js?url'
ace.config.setModuleUrl('ace/snippets/golang', snippets_golang)
import snippets_graphqlschema from 'ace-builds/src-noconflict/snippets/graphqlschema.js?url'
ace.config.setModuleUrl('ace/snippets/graphqlschema', snippets_graphqlschema)
import snippets_groovy from 'ace-builds/src-noconflict/snippets/groovy.js?url'
ace.config.setModuleUrl('ace/snippets/groovy', snippets_groovy)
import snippets_haml from 'ace-builds/src-noconflict/snippets/haml.js?url'
ace.config.setModuleUrl('ace/snippets/haml', snippets_haml)
import snippets_handlebars from 'ace-builds/src-noconflict/snippets/handlebars.js?url'
ace.config.setModuleUrl('ace/snippets/handlebars', snippets_handlebars)
import snippets_haskell from 'ace-builds/src-noconflict/snippets/haskell.js?url'
ace.config.setModuleUrl('ace/snippets/haskell', snippets_haskell)
import snippets_haskell_cabal from 'ace-builds/src-noconflict/snippets/haskell_cabal.js?url'
ace.config.setModuleUrl('ace/snippets/haskell_cabal', snippets_haskell_cabal)
import snippets_haxe from 'ace-builds/src-noconflict/snippets/haxe.js?url'
ace.config.setModuleUrl('ace/snippets/haxe', snippets_haxe)
import snippets_hjson from 'ace-builds/src-noconflict/snippets/hjson.js?url'
ace.config.setModuleUrl('ace/snippets/hjson', snippets_hjson)
import snippets_html from 'ace-builds/src-noconflict/snippets/html.js?url'
ace.config.setModuleUrl('ace/snippets/html', snippets_html)
import snippets_html_elixir from 'ace-builds/src-noconflict/snippets/html_elixir.js?url'
ace.config.setModuleUrl('ace/snippets/html_elixir', snippets_html_elixir)
import snippets_html_ruby from 'ace-builds/src-noconflict/snippets/html_ruby.js?url'
ace.config.setModuleUrl('ace/snippets/html_ruby', snippets_html_ruby)
import snippets_ini from 'ace-builds/src-noconflict/snippets/ini.js?url'
ace.config.setModuleUrl('ace/snippets/ini', snippets_ini)
import snippets_io from 'ace-builds/src-noconflict/snippets/io.js?url'
ace.config.setModuleUrl('ace/snippets/io', snippets_io)
import snippets_jack from 'ace-builds/src-noconflict/snippets/jack.js?url'
ace.config.setModuleUrl('ace/snippets/jack', snippets_jack)
import snippets_jade from 'ace-builds/src-noconflict/snippets/jade.js?url'
ace.config.setModuleUrl('ace/snippets/jade', snippets_jade)
import snippets_java from 'ace-builds/src-noconflict/snippets/java.js?url'
ace.config.setModuleUrl('ace/snippets/java', snippets_java)
import snippets_javascript from 'ace-builds/src-noconflict/snippets/javascript.js?url'
ace.config.setModuleUrl('ace/snippets/javascript', snippets_javascript)
import snippets_json from 'ace-builds/src-noconflict/snippets/json.js?url'
ace.config.setModuleUrl('ace/snippets/json', snippets_json)
import snippets_json5 from 'ace-builds/src-noconflict/snippets/json5.js?url'
ace.config.setModuleUrl('ace/snippets/json5', snippets_json5)
import snippets_jsoniq from 'ace-builds/src-noconflict/snippets/jsoniq.js?url'
ace.config.setModuleUrl('ace/snippets/jsoniq', snippets_jsoniq)
import snippets_jsp from 'ace-builds/src-noconflict/snippets/jsp.js?url'
ace.config.setModuleUrl('ace/snippets/jsp', snippets_jsp)
import snippets_jssm from 'ace-builds/src-noconflict/snippets/jssm.js?url'
ace.config.setModuleUrl('ace/snippets/jssm', snippets_jssm)
import snippets_jsx from 'ace-builds/src-noconflict/snippets/jsx.js?url'
ace.config.setModuleUrl('ace/snippets/jsx', snippets_jsx)
import snippets_julia from 'ace-builds/src-noconflict/snippets/julia.js?url'
ace.config.setModuleUrl('ace/snippets/julia', snippets_julia)
import snippets_kotlin from 'ace-builds/src-noconflict/snippets/kotlin.js?url'
ace.config.setModuleUrl('ace/snippets/kotlin', snippets_kotlin)
import snippets_latex from 'ace-builds/src-noconflict/snippets/latex.js?url'
ace.config.setModuleUrl('ace/snippets/latex', snippets_latex)
import snippets_latte from 'ace-builds/src-noconflict/snippets/latte.js?url'
ace.config.setModuleUrl('ace/snippets/latte', snippets_latte)
import snippets_less from 'ace-builds/src-noconflict/snippets/less.js?url'
ace.config.setModuleUrl('ace/snippets/less', snippets_less)
import snippets_liquid from 'ace-builds/src-noconflict/snippets/liquid.js?url'
ace.config.setModuleUrl('ace/snippets/liquid', snippets_liquid)
import snippets_lisp from 'ace-builds/src-noconflict/snippets/lisp.js?url'
ace.config.setModuleUrl('ace/snippets/lisp', snippets_lisp)
import snippets_livescript from 'ace-builds/src-noconflict/snippets/livescript.js?url'
ace.config.setModuleUrl('ace/snippets/livescript', snippets_livescript)
import snippets_logiql from 'ace-builds/src-noconflict/snippets/logiql.js?url'
ace.config.setModuleUrl('ace/snippets/logiql', snippets_logiql)
import snippets_logtalk from 'ace-builds/src-noconflict/snippets/logtalk.js?url'
ace.config.setModuleUrl('ace/snippets/logtalk', snippets_logtalk)
import snippets_lsl from 'ace-builds/src-noconflict/snippets/lsl.js?url'
ace.config.setModuleUrl('ace/snippets/lsl', snippets_lsl)
import snippets_lua from 'ace-builds/src-noconflict/snippets/lua.js?url'
ace.config.setModuleUrl('ace/snippets/lua', snippets_lua)
import snippets_luapage from 'ace-builds/src-noconflict/snippets/luapage.js?url'
ace.config.setModuleUrl('ace/snippets/luapage', snippets_luapage)
import snippets_lucene from 'ace-builds/src-noconflict/snippets/lucene.js?url'
ace.config.setModuleUrl('ace/snippets/lucene', snippets_lucene)
import snippets_makefile from 'ace-builds/src-noconflict/snippets/makefile.js?url'
ace.config.setModuleUrl('ace/snippets/makefile', snippets_makefile)
import snippets_markdown from 'ace-builds/src-noconflict/snippets/markdown.js?url'
ace.config.setModuleUrl('ace/snippets/markdown', snippets_markdown)
import snippets_mask from 'ace-builds/src-noconflict/snippets/mask.js?url'
ace.config.setModuleUrl('ace/snippets/mask', snippets_mask)
import snippets_matlab from 'ace-builds/src-noconflict/snippets/matlab.js?url'
ace.config.setModuleUrl('ace/snippets/matlab', snippets_matlab)
import snippets_maze from 'ace-builds/src-noconflict/snippets/maze.js?url'
ace.config.setModuleUrl('ace/snippets/maze', snippets_maze)
import snippets_mediawiki from 'ace-builds/src-noconflict/snippets/mediawiki.js?url'
ace.config.setModuleUrl('ace/snippets/mediawiki', snippets_mediawiki)
import snippets_mel from 'ace-builds/src-noconflict/snippets/mel.js?url'
ace.config.setModuleUrl('ace/snippets/mel', snippets_mel)
import snippets_mips from 'ace-builds/src-noconflict/snippets/mips.js?url'
ace.config.setModuleUrl('ace/snippets/mips', snippets_mips)
import snippets_mixal from 'ace-builds/src-noconflict/snippets/mixal.js?url'
ace.config.setModuleUrl('ace/snippets/mixal', snippets_mixal)
import snippets_mushcode from 'ace-builds/src-noconflict/snippets/mushcode.js?url'
ace.config.setModuleUrl('ace/snippets/mushcode', snippets_mushcode)
import snippets_mysql from 'ace-builds/src-noconflict/snippets/mysql.js?url'
ace.config.setModuleUrl('ace/snippets/mysql', snippets_mysql)
import snippets_nginx from 'ace-builds/src-noconflict/snippets/nginx.js?url'
ace.config.setModuleUrl('ace/snippets/nginx', snippets_nginx)
import snippets_nim from 'ace-builds/src-noconflict/snippets/nim.js?url'
ace.config.setModuleUrl('ace/snippets/nim', snippets_nim)
import snippets_nix from 'ace-builds/src-noconflict/snippets/nix.js?url'
ace.config.setModuleUrl('ace/snippets/nix', snippets_nix)
import snippets_nsis from 'ace-builds/src-noconflict/snippets/nsis.js?url'
ace.config.setModuleUrl('ace/snippets/nsis', snippets_nsis)
import snippets_nunjucks from 'ace-builds/src-noconflict/snippets/nunjucks.js?url'
ace.config.setModuleUrl('ace/snippets/nunjucks', snippets_nunjucks)
import snippets_objectivec from 'ace-builds/src-noconflict/snippets/objectivec.js?url'
ace.config.setModuleUrl('ace/snippets/objectivec', snippets_objectivec)
import snippets_ocaml from 'ace-builds/src-noconflict/snippets/ocaml.js?url'
ace.config.setModuleUrl('ace/snippets/ocaml', snippets_ocaml)
import snippets_pascal from 'ace-builds/src-noconflict/snippets/pascal.js?url'
ace.config.setModuleUrl('ace/snippets/pascal', snippets_pascal)
import snippets_perl from 'ace-builds/src-noconflict/snippets/perl.js?url'
ace.config.setModuleUrl('ace/snippets/perl', snippets_perl)
import snippets_pgsql from 'ace-builds/src-noconflict/snippets/pgsql.js?url'
ace.config.setModuleUrl('ace/snippets/pgsql', snippets_pgsql)
import snippets_php from 'ace-builds/src-noconflict/snippets/php.js?url'
ace.config.setModuleUrl('ace/snippets/php', snippets_php)
import snippets_php_laravel_blade from 'ace-builds/src-noconflict/snippets/php_laravel_blade.js?url'
ace.config.setModuleUrl('ace/snippets/php_laravel_blade', snippets_php_laravel_blade)
import snippets_pig from 'ace-builds/src-noconflict/snippets/pig.js?url'
ace.config.setModuleUrl('ace/snippets/pig', snippets_pig)
import snippets_plain_text from 'ace-builds/src-noconflict/snippets/plain_text.js?url'
ace.config.setModuleUrl('ace/snippets/plain_text', snippets_plain_text)
import snippets_powershell from 'ace-builds/src-noconflict/snippets/powershell.js?url'
ace.config.setModuleUrl('ace/snippets/powershell', snippets_powershell)
import snippets_praat from 'ace-builds/src-noconflict/snippets/praat.js?url'
ace.config.setModuleUrl('ace/snippets/praat', snippets_praat)
import snippets_prisma from 'ace-builds/src-noconflict/snippets/prisma.js?url'
ace.config.setModuleUrl('ace/snippets/prisma', snippets_prisma)
import snippets_prolog from 'ace-builds/src-noconflict/snippets/prolog.js?url'
ace.config.setModuleUrl('ace/snippets/prolog', snippets_prolog)
import snippets_properties from 'ace-builds/src-noconflict/snippets/properties.js?url'
ace.config.setModuleUrl('ace/snippets/properties', snippets_properties)
import snippets_protobuf from 'ace-builds/src-noconflict/snippets/protobuf.js?url'
ace.config.setModuleUrl('ace/snippets/protobuf', snippets_protobuf)
import snippets_puppet from 'ace-builds/src-noconflict/snippets/puppet.js?url'
ace.config.setModuleUrl('ace/snippets/puppet', snippets_puppet)
import snippets_python from 'ace-builds/src-noconflict/snippets/python.js?url'
ace.config.setModuleUrl('ace/snippets/python', snippets_python)
import snippets_qml from 'ace-builds/src-noconflict/snippets/qml.js?url'
ace.config.setModuleUrl('ace/snippets/qml', snippets_qml)
import snippets_r from 'ace-builds/src-noconflict/snippets/r.js?url'
ace.config.setModuleUrl('ace/snippets/r', snippets_r)
import snippets_raku from 'ace-builds/src-noconflict/snippets/raku.js?url'
ace.config.setModuleUrl('ace/snippets/raku', snippets_raku)
import snippets_razor from 'ace-builds/src-noconflict/snippets/razor.js?url'
ace.config.setModuleUrl('ace/snippets/razor', snippets_razor)
import snippets_rdoc from 'ace-builds/src-noconflict/snippets/rdoc.js?url'
ace.config.setModuleUrl('ace/snippets/rdoc', snippets_rdoc)
import snippets_red from 'ace-builds/src-noconflict/snippets/red.js?url'
ace.config.setModuleUrl('ace/snippets/red', snippets_red)
import snippets_redshift from 'ace-builds/src-noconflict/snippets/redshift.js?url'
ace.config.setModuleUrl('ace/snippets/redshift', snippets_redshift)
import snippets_rhtml from 'ace-builds/src-noconflict/snippets/rhtml.js?url'
ace.config.setModuleUrl('ace/snippets/rhtml', snippets_rhtml)
import snippets_rst from 'ace-builds/src-noconflict/snippets/rst.js?url'
ace.config.setModuleUrl('ace/snippets/rst', snippets_rst)
import snippets_ruby from 'ace-builds/src-noconflict/snippets/ruby.js?url'
ace.config.setModuleUrl('ace/snippets/ruby', snippets_ruby)
import snippets_rust from 'ace-builds/src-noconflict/snippets/rust.js?url'
ace.config.setModuleUrl('ace/snippets/rust', snippets_rust)
import snippets_sass from 'ace-builds/src-noconflict/snippets/sass.js?url'
ace.config.setModuleUrl('ace/snippets/sass', snippets_sass)
import snippets_scad from 'ace-builds/src-noconflict/snippets/scad.js?url'
ace.config.setModuleUrl('ace/snippets/scad', snippets_scad)
import snippets_scala from 'ace-builds/src-noconflict/snippets/scala.js?url'
ace.config.setModuleUrl('ace/snippets/scala', snippets_scala)
import snippets_scheme from 'ace-builds/src-noconflict/snippets/scheme.js?url'
ace.config.setModuleUrl('ace/snippets/scheme', snippets_scheme)
import snippets_scrypt from 'ace-builds/src-noconflict/snippets/scrypt.js?url'
ace.config.setModuleUrl('ace/snippets/scrypt', snippets_scrypt)
import snippets_scss from 'ace-builds/src-noconflict/snippets/scss.js?url'
ace.config.setModuleUrl('ace/snippets/scss', snippets_scss)
import snippets_sh from 'ace-builds/src-noconflict/snippets/sh.js?url'
ace.config.setModuleUrl('ace/snippets/sh', snippets_sh)
import snippets_sjs from 'ace-builds/src-noconflict/snippets/sjs.js?url'
ace.config.setModuleUrl('ace/snippets/sjs', snippets_sjs)
import snippets_slim from 'ace-builds/src-noconflict/snippets/slim.js?url'
ace.config.setModuleUrl('ace/snippets/slim', snippets_slim)
import snippets_smarty from 'ace-builds/src-noconflict/snippets/smarty.js?url'
ace.config.setModuleUrl('ace/snippets/smarty', snippets_smarty)
import snippets_smithy from 'ace-builds/src-noconflict/snippets/smithy.js?url'
ace.config.setModuleUrl('ace/snippets/smithy', snippets_smithy)
import snippets_snippets from 'ace-builds/src-noconflict/snippets/snippets.js?url'
ace.config.setModuleUrl('ace/snippets/snippets', snippets_snippets)
import snippets_soy_template from 'ace-builds/src-noconflict/snippets/soy_template.js?url'
ace.config.setModuleUrl('ace/snippets/soy_template', snippets_soy_template)
import snippets_space from 'ace-builds/src-noconflict/snippets/space.js?url'
ace.config.setModuleUrl('ace/snippets/space', snippets_space)
import snippets_sparql from 'ace-builds/src-noconflict/snippets/sparql.js?url'
ace.config.setModuleUrl('ace/snippets/sparql', snippets_sparql)
import snippets_sql from 'ace-builds/src-noconflict/snippets/sql.js?url'
ace.config.setModuleUrl('ace/snippets/sql', snippets_sql)
import snippets_sqlserver from 'ace-builds/src-noconflict/snippets/sqlserver.js?url'
ace.config.setModuleUrl('ace/snippets/sqlserver', snippets_sqlserver)
import snippets_stylus from 'ace-builds/src-noconflict/snippets/stylus.js?url'
ace.config.setModuleUrl('ace/snippets/stylus', snippets_stylus)
import snippets_svg from 'ace-builds/src-noconflict/snippets/svg.js?url'
ace.config.setModuleUrl('ace/snippets/svg', snippets_svg)
import snippets_swift from 'ace-builds/src-noconflict/snippets/swift.js?url'
ace.config.setModuleUrl('ace/snippets/swift', snippets_swift)
import snippets_tcl from 'ace-builds/src-noconflict/snippets/tcl.js?url'
ace.config.setModuleUrl('ace/snippets/tcl', snippets_tcl)
import snippets_terraform from 'ace-builds/src-noconflict/snippets/terraform.js?url'
ace.config.setModuleUrl('ace/snippets/terraform', snippets_terraform)
import snippets_tex from 'ace-builds/src-noconflict/snippets/tex.js?url'
ace.config.setModuleUrl('ace/snippets/tex', snippets_tex)
import snippets_text from 'ace-builds/src-noconflict/snippets/text.js?url'
ace.config.setModuleUrl('ace/snippets/text', snippets_text)
import snippets_textile from 'ace-builds/src-noconflict/snippets/textile.js?url'
ace.config.setModuleUrl('ace/snippets/textile', snippets_textile)
import snippets_toml from 'ace-builds/src-noconflict/snippets/toml.js?url'
ace.config.setModuleUrl('ace/snippets/toml', snippets_toml)
import snippets_tsx from 'ace-builds/src-noconflict/snippets/tsx.js?url'
ace.config.setModuleUrl('ace/snippets/tsx', snippets_tsx)
import snippets_turtle from 'ace-builds/src-noconflict/snippets/turtle.js?url'
ace.config.setModuleUrl('ace/snippets/turtle', snippets_turtle)
import snippets_twig from 'ace-builds/src-noconflict/snippets/twig.js?url'
ace.config.setModuleUrl('ace/snippets/twig', snippets_twig)
import snippets_typescript from 'ace-builds/src-noconflict/snippets/typescript.js?url'
ace.config.setModuleUrl('ace/snippets/typescript', snippets_typescript)
import snippets_vala from 'ace-builds/src-noconflict/snippets/vala.js?url'
ace.config.setModuleUrl('ace/snippets/vala', snippets_vala)
import snippets_vbscript from 'ace-builds/src-noconflict/snippets/vbscript.js?url'
ace.config.setModuleUrl('ace/snippets/vbscript', snippets_vbscript)
import snippets_velocity from 'ace-builds/src-noconflict/snippets/velocity.js?url'
ace.config.setModuleUrl('ace/snippets/velocity', snippets_velocity)
import snippets_verilog from 'ace-builds/src-noconflict/snippets/verilog.js?url'
ace.config.setModuleUrl('ace/snippets/verilog', snippets_verilog)
import snippets_vhdl from 'ace-builds/src-noconflict/snippets/vhdl.js?url'
ace.config.setModuleUrl('ace/snippets/vhdl', snippets_vhdl)
import snippets_visualforce from 'ace-builds/src-noconflict/snippets/visualforce.js?url'
ace.config.setModuleUrl('ace/snippets/visualforce', snippets_visualforce)
import snippets_wollok from 'ace-builds/src-noconflict/snippets/wollok.js?url'
ace.config.setModuleUrl('ace/snippets/wollok', snippets_wollok)
import snippets_xml from 'ace-builds/src-noconflict/snippets/xml.js?url'
ace.config.setModuleUrl('ace/snippets/xml', snippets_xml)
import snippets_xquery from 'ace-builds/src-noconflict/snippets/xquery.js?url'
ace.config.setModuleUrl('ace/snippets/xquery', snippets_xquery)
import snippets_yaml from 'ace-builds/src-noconflict/snippets/yaml.js?url'
ace.config.setModuleUrl('ace/snippets/yaml', snippets_yaml)
import snippets_zeek from 'ace-builds/src-noconflict/snippets/zeek.js?url'
ace.config.setModuleUrl('ace/snippets/zeek', snippets_zeek)
