import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    dropText: String,
  };
  connect() {}
  dragStart(event) {
    event.dataTransfer.setData("text/plain", this.dropTextValue);
  }
}
