import { Controller } from '@hotwired/stimulus'
import { useResize } from 'stimulus-use'
import * as echarts from 'echarts'

export default class extends Controller {

  static values = {
    params: Object
  }

  connect() {
    useResize(this)
    this.chart = echarts.init(this.element, null, { renderer: 'svg' })
    this.chart.setOption(this.paramsValue)
  }

  disconnect() {
    this.chart.dispose()
  }

  resize() {
    this.chart.resize()
  }

}
