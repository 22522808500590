import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sum", "element"];

  update_sum(element) {
    let value = parseInt(element.dataset.sumValue);
    if (isNaN(value)) {
      value = 0;
    }
    const group = element.dataset.sumGroup;
    this.sum += value;

    if (this.sumGroups.hasOwnProperty(group)) {
      this.sum -= this.sumGroups[group];
    }
    this.sumGroups[group] = value;
  }

  connect() {
    this.sum = 0;
    this.sumGroups = {};

    this.sumTarget.textContent = this.sum;
    this.elementTargets.forEach((element) => {
      if (element.checked) {
        this.update_sum(element);
      }
    });
    this.sumTarget.textContent = this.sum;
  }

  add(event) {
    // const value = parseInt(event.currentTarget.dataset.sumValue);
    // const group = event.currentTarget.dataset.sumGroup;
    // this.sum += value;
    // if (this.sumGroups.hasOwnProperty(group)) {
    //   this.sum -= this.sumGroups[group];
    // }
    // this.sumGroups[group] = value;

    this.update_sum(event.currentTarget);
    this.sumTarget.textContent = this.sum;
  }
}
