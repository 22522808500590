// manages all nested audio players
// whenever some playser starts playing, all others are paused

import { Controller } from "@hotwired/stimulus"
import 'arrive'

export default class extends Controller {

  connect() {
    this.element.arrive('audio', { existing: true }, e => {
      e.addEventListener('play', evt => {
        this.element.querySelectorAll('audio').forEach(elem => {
          if (elem != e) {
            elem.pause()
          }
        })
      })
    })
  }

}
