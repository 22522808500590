import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.element.querySelectorAll('.cb-main-nav-item').forEach(el => {
      let link = el.querySelector('.cb-main-nav-header a')
      let submenu = el.querySelector('.cb-main-nav-submenu')
      link.addEventListener('click', (evt) => {
        evt.preventDefault()
        if (submenu.classList.contains('d-none')) {
          this.closeAll()
          submenu.classList.remove('d-none')
        } else {
          submenu.classList.add('d-none')
        }
      })
      if (submenu) {
        submenu.querySelectorAll('a').forEach(node => {
          node.addEventListener('click', (evt) => this.closeAll())
        })
      }
    })
  }

  closeAll() {
    this.element.querySelectorAll('.cb-main-nav-submenu').forEach(node => {
      node.classList.add('d-none')
    })
  }

}
