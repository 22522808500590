import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {}

  cleanParams(event) {
    // disable all empty fields, so they are not transmitted into ugly long URL
    event.preventDefault()
    this.element.querySelectorAll('input, select, button').forEach(e => {
      if (!e.value) { e.setAttribute('disabled', true) }
    })
    this.element.requestSubmit()
  }

}
